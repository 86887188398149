<template>
	<v-sheet class="customer" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex align-items-center">
						<p class="my-auto font-level-3-bold" style="font-size: 20px">
							<ShowValue :object="customer" object-key="company_name" label="company name"></ShowValue>
						</p>
						<ShowBarcode
							:object="customer"
							object-key="barcode"
							tooltip
							tooltip-text="customer #"
							click-to-copy
							label="barcode"
						></ShowBarcode>
						<v-spacer></v-spacer>
						<v-btn
							v-if="getPermission('customer::edit')"
							v-on:click="doAction('edit')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
						>
						<v-btn
							v-on:click="backToListing('customer')"
							color="grey lighten-3"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-arrow-left</v-icon>Back</v-btn
						>
					</div>
					<div class="d-flex mt-4">
						<div class="w-10 pr-3">
							<ImageTemplate circle :src="customer.image"></ImageTemplate>
						</div>
						<div class="w-90 d-flex pr-3">
							<div class="width-66 mt-0">
								<div class="width-100 d-flex flex-wrap mt-0">
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600">Display Name : </span>
											<span class="fw-500">
												<ShowValue
													:object="customer"
													object-key="display_name"
													label="display name"
												></ShowValue>
											</span>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600">Company Email : </span>
											<span class="fw-500">
												<ShowValue :object="customer" object-key="email" label="email address"></ShowValue>
											</span>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600 mr-2">Company Phone : </span>
											<span class="fw-500">
												<ShowValue :object="customer" object-key="phone" label="phone"></ShowValue>
											</span>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600 mr-2">UEN : </span>
											<span class="fw-500">
												<ShowValue :object="customer" object-key="uen" label="UEN"></ShowValue>
											</span>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600 mr-2">Group : </span>
											<span class="fw-500">
												<ShowValue :object="customer" object-key="group" label="group"></ShowValue>
											</span>
										</div>
									</div>
									<div class="width-50">
										<div class="mt-2">
											<span class="fw-600 mr-2">Website : </span>
											<span class="fw-500">
												<ShowValue :object="customer" object-key="website" label="website"></ShowValue>
											</span>
										</div>
									</div>
									<div class="w-100">
										<div class="mt-2 d-flex">
											<span class="fw-600 no-wrap mr-2">Address : </span>
											<span class="fw-500 ml-1">
												<div
													v-if="
														customer.address_line_1 ||
														customer.address_line_2 ||
														customer.unit_number ||
														customer.address_country ||
														customer.address_postal_code
													"
												>
													<template v-if="customer.address_line_1"> {{ customer.address_line_1 }}, </template>
													<template v-if="customer.address_line_2"> {{ customer.address_line_2 }}, </template>
													<template v-if="customer.unit_number"> {{ customer.unit_number }}, </template>
													<template v-if="customer.address_country"> {{ customer.address_country }}, </template>
													<template v-if="customer.address_postal_code">
														{{ customer.address_postal_code }}
													</template>
												</div>
											</span>
										</div>
										<div class="mt-2">
											<span class="fw-600 mr-2">Company FAX : </span>
											<span class="fw-500">
												<ShowValue :object="customer" object-key="fax" label="Company FAX"></ShowValue>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div class="width-33">
								<div class="width-100">
									<div class="mt-2">
										<span class="fw-600 mr-2">Created date : </span>
										<span class="fw-500" v-if="customer.added_at">
											{{ formatDateTime(customer.added_at) }}
										</span>
										<em v-else class="text-muted">no created date</em>
									</div>
								</div>
								<div class="width-100">
									<div class="mt-2">
										<span class="fw-600 mr-2">Created By : </span>
										<span class="fw-500">
											<ShowValue :object="customer" object-key="added_by" label="created by"></ShowValue>
										</span>
									</div>
								</div>
								<div class="width-100">
									<div class="mt-2">
										<span class="fw-600 mr-2">Updated date : </span>
										<span class="fw-500" v-if="customer.updated_at">
											{{ formatDateTime(customer.updated_at) }}
										</span>
										<em v-else class="text-muted">no updated date</em>
									</div>
								</div>
								<div class="width-100">
									<div class="mt-2">
										<span class="fw-600 mr-2">Updated By : </span>
										<span class="fw-500">
											<ShowValue :object="customer" object-key="updated_by" label="updated by"></ShowValue>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="customerTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Details</v-tab
					>
					<v-tab href="#address-book">
						<v-icon small left>mdi-format-list-text</v-icon>Addresses({{ address_count }})</v-tab
					>
					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Attachments</v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
				</v-tabs>
				<v-tabs-items v-model="customerTab">
					<v-tab-item value="detail">
						<v-expansion-panels
							flat
							accordion
							tile
							v-model="expansion_panel"
							class="mt-1 transparent-panels overflow-y"
							style="max-height: calc(100vh - 250px)"
						>
							<v-expansion-panel>
								<v-expansion-panel-header>
									<v-layout>
										<v-flex>Other detail</v-flex>
									</v-layout>
								</v-expansion-panel-header>
								<v-expansion-panel-content class="contact-person-list">
									<div class="w-100">
										<div class="d-flex">
											<span class="fw-600 no-wrap mr-2">Remark (Internal) : </span>
											<!-- {{ customer.remark }} -->
											<span class="fw-500">
												<ShowTextAreaValue
													:object="customer"
													object-key="remark"
													label="remark"
												></ShowTextAreaValue>
											</span>
										</div>
									</div>
								</v-expansion-panel-content>
							</v-expansion-panel>
							<v-expansion-panel>
								<v-expansion-panel-header>
									<v-layout>
										<v-flex md10>Contact Persons ({{ contact_person.length }})</v-flex>
										<v-flex md2 class="text-right">
											<v-icon
												v-if="getPermission('customer::edit')"
												v-on:click.stop.prevent="addPerson()"
												dense
												color="blue darken-4"
												class="mx-2"
												>mdi-plus-circle-outline</v-icon
											>
										</v-flex>
									</v-layout>
								</v-expansion-panel-header>
								<v-expansion-panel-content class="contact-person-list">
									<template v-if="contact_person.length">
										<v-layout class="flex-wrap">
											<v-flex md6 lg4 v-for="(person, index) in contact_person" :key="index" class="pa-2">
												<div class="border pa-3 h-100">
													<div>
														<p class="text-truncate mb-1 bold-600 text-capitalize">
															#{{ person.barcode }}
															<v-chip class="mx-2" label x-small color="green" v-if="person.primary == 1">
																<span class="white--text">Primary</span>
															</v-chip>
														</p>
														<v-menu left tile offset-x>
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	v-if="getPermission('customer::edit')"
																	icon
																	small
																	color="blue darken-4"
																	class="btx-icon-top-right"
																	v-bind="attrs"
																	v-on="on"
																>
																	<v-icon small>mdi-cog</v-icon>
																</v-btn>
															</template>

															<v-list small>
																<v-list-item link v-on:click="addPerson(person)">
																	<v-list-item-title>
																		<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
																		Edit
																	</v-list-item-title>
																</v-list-item>
																<v-list-item link v-if="!person.primary" v-on:click="markAsPrimary(person.uuid)">
																	<v-list-item-title>
																		<v-icon small left color="blue darken-4">mdi-check-bold</v-icon>
																		Mark as Primary</v-list-item-title
																	>
																</v-list-item>
																<template v-if="contact_person.length > 1">
																	<v-list-item
																		link
																		v-if="!person.primary"
																		v-on:click="deletePerson(person.uuid)"
																		class="red--text text--lighten-1"
																	>
																		<v-list-item-title class="red--text text--lighten-1">
																			<v-icon small left color="red">mdi-delete</v-icon>
																			Delete</v-list-item-title
																		>
																	</v-list-item>
																</template>
															</v-list>
														</v-menu>
													</div>
													<p class="mb-1" v-if="person.display_name">
														<v-icon left small>mdi-account</v-icon> {{ person.display_name }}
													</p>
													<p class="mb-1 text-lowercase" v-if="person.email">
														<v-icon left small>mdi-email</v-icon>{{ person.email }}
													</p>
													<p class="mb-1 text-lowercase" v-if="person.phone">
														<v-icon left small>mdi-phone</v-icon>{{ person.phone }}
													</p>
													<p class="mb-1 text-lowercase" v-if="person.did">
														<v-icon left small>mdi-phone</v-icon>{{ person.did }}
														<span class="fw-600 text-uppercase blue--text text--darken-4"> (DID)</span>
													</p>
													<p class="mb-1" v-if="person.designation">
														<v-icon left small>mdi-briefcase</v-icon> {{ person.designation }}
													</p>
												</div>
											</v-flex>
										</v-layout>
									</template>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-tab-item>
					<v-tab-item value="address-book">
						<CustomerAddressBook
							v-if="customerTab == 'address-book' && customer.id"
							type-text="Customer"
							type="contact"
							:new-address="addressDialog"
							:type-uuid="uuid"
							:customer-id="customer.id"
							v-on:close="addressDialog = false"
							v-on:updateContactPersonList="getCustomer"
						></CustomerAddressBook>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="customerTab == 'file'"
							class="mx-4"
							type-text="Customer Attachments"
							type="customer"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="customerTab == 'event'"
							class="mx-4"
							type-text="Customer"
							type="customer"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item value="checked-out" v-if="false">
						<ReservationListing
							v-if="customerTab == 'checked-out'"
							class="mx-4"
							type-text="Customer"
							type="customer"
							:type-uuid="uuid"
							status="checked-out"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="reservation" v-if="false">
						<ReservationListing
							v-if="customerTab == 'reservation'"
							class="mx-4"
							type-text="Customer"
							type="customer"
							:type-uuid="uuid"
							status="reserved"
						></ReservationListing>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Customer"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					customer.activated
						? 'To preserve associated records, you could de-activate the Customer instead.'
						: null
				"
			>
				<template v-if="customer.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Customer"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Customer"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
			<CreateOrUpdatePerson
				v-if="personDialog && customer.id"
				type="customer"
				:person-dialog="personDialog"
				:vendor-uuid="uuid"
				:person="personModel"
				:contactUuid="customer.uuid"
				:contact-id="customer.id"
				v-on:close="personDialog = false"
				v-on:success="getCustomer"
			></CreateOrUpdatePerson>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<style>
.w-15 {
	width: 125px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
	GetCustomer,
	MarkAsDefaultContactPerson,
	DeleteContactPerson,
} from "@/core/lib/contact.lib";
import ImageTemplate from "@/view/components/Image";
import ShowValue from "@/view/components/ShowValue";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { isEmpty } from "lodash";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import EventListing from "@/view/components/EventListing";
import ReservationListing from "@/view/components/ReservationListing";
import CustomerAddressBook from "@/view/components/MemberAddressBook";
import Files from "@/view/components/Files";
import CreateOrUpdatePerson from "@/view/components/CreateOrUpdatePerson";
import ShowBarcode from "../../../components/ShowBarcode.vue";

export default {
	name: "customer-detail",
	title: "Detail Customer",
	data() {
		return {
			uuid: null,
			barcode: null,
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			personDialog: false,
			deleteText: null,
			address_count: 0,
			contact_person: [
				{
					id: null,
					uuid: null,
					salutation: "mr",
					first_name: "John",
					last_name: "parker",
					email: "john@bthrust.com",
					work_phone: "6585858586",
					full_name: "John Parker",
					default: false,
					mobile: "5856856585",
				},
				{
					id: null,
					uuid: null,
					salutation: "mr",
					first_name: "John",
					last_name: "parker",
					email: "john@bthrust.com",
					work_phone: "6585858586",
					full_name: "John Parker",
					default: false,
					mobile: "5856856585",
				},
			],
			formValid: true,
			personModel: {},
			deleteURL: null,
			deleteDialog: false,
			addressDialog: false,
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: true,
			pageLoading: false,
			/* customerTab: null, */
			expansion_panel: 0,
			customer: {
				barcode: "CUS00000234",
				company_name: "Bthrust",
				display_name: "John Parker",
				email: "john@bthrust.com",
				phone: "9658965896",
				uen: "UEN0001023",
				address: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt, nam?",
			},
		};
	},
	computed: {
		customerTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.customerTab) {
					this.$router.push({ query });
				}
			},
			get() {
				return this.$route.query.tab || "customer";
			},
		},
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "customer-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "clone":
					this.$router.push({
						name: "customer-create",
						query: { clone: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "new-address":
					this.addressDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		markAsPrimary(uuid) {
			MarkAsDefaultContactPerson(uuid)
				.then(() => {
					this.getCustomer();
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		deletePerson(uuid) {
			DeleteContactPerson({ url: "contact", uuid: uuid })
				.then(() => {
					this.getCustomer();
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		getCustomer() {
			GetCustomer(this.uuid)
				.then((data) => {
					const customer = data.customer;
					this.barcode = customer.barcode;
					this.customer = customer;
					this.contact_person = data.contact_person;
					this.address_count = data.address && data.address.length;
					this.deleteText = `#${customer.barcode} - ${customer.display_name}`;
					this.deleteURL = `contact/${customer.uuid}`;
					this.activateText = `${customer.display_name}`;
					this.activateURL = `contact/customer/${customer.uuid}/activate`;
					this.deactivateText = `${customer.display_name}`;
					this.deactivateURL = `contact/customer/${customer.uuid}/de-activate`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Customer", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		addPerson(person) {
			this.personDialog = true;
			this.personModel = new Object();
			if (person && !isEmpty(person)) {
				this.personModel = new Object({
					id: person.id || null,
					uuid: person.uuid || null,
					salutation: person.title || null,
					first_name: person.first_name || null,
					last_name: person.last_name || null,
					email: person.email || null,
					mobile: person.phone || null,
					did: person.did || null,
					display_name: person.display_name || null,
					designation: person.designation || null,
					primary: person.primary || null,
				});
			}
		},
	},
	components: {
		ShowValue,
		ShowTextAreaValue,
		CustomerAddressBook,
		EventListing,
		ImageTemplate,
		DeleteTemplate,
		ActivateTemplate,
		CreateOrUpdatePerson,
		Files,
		DeactivateTemplate,
		ReservationListing,
		ShowBarcode,
	},
	beforeMount() {
		const { uuid } = this.$route.params;
		this.uuid = uuid;
		let query = { ...this.$route.query };
		if (query.expansion == "contact-person") {
			this.expansion_panel = 1;
		}
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Customer", disabled: true },
			{ text: "Detail", disabled: true },
		]);
		if (!this.uuid) {
			this.goBack();
		}
		this.getCustomer();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
